
import React from 'react'

function mainVortex() {

  return (
    <div id="Vortex" className="justify-content-center container">
      <div>
        <div className="text-center pt-3 fs-1">Vortex</div>
      </div>
    </div>
  );
}

export default mainVortex
