
import React from 'react'

function mainDeepValueTrades() {

  return (
    <div id="DeepValueTrade" className="justify-content-center container">
      <div>
        <div className="text-center pt-3 fs-1">Deep Value Trades</div>
      </div>
    </div>
  );
}

export default mainDeepValueTrades
